import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './auth/login.component';
import { RegisterComponent } from './auth/register.component';
import { ForgotPasswordComponent } from './auth/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { UsersComponent, AddUserModal } from './users/users.component'
import { MatDialogModule } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatProgressSpinnerModule, MatRadioModule, MatSnackBarModule, MatDatepickerModule, MatNativeDateModule } from "@angular/material";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { AngularEditorModule } from '@kolkov/angular-editor';


import { SignaturePadModule } from 'angular2-signaturepad';
import { RoleManagementComponent } from './role-management/role-management.component';
import { AddRoleComponent } from './role-management/add-role/add-role.component';
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import { AddEmailTemplateComponent } from './email-templates/add-email-template/add-email-template.component';

import { ExportAsModule } from 'ngx-export-as';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConfirmationDialog } from './confirm-box/confirmation-dialog.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SafeHtmlPipe } from './safe-html';;
;
import { CompaniesComponent } from './companies/companies.component'
    ;
import { SignUpComponent } from './auth/sign-up/sign-up/sign-up.component'
import { NewHomeComponent } from './new-home/new-home.component';;
import { LoginStatusComponent } from './login-status/login-status.component'


@NgModule({
    imports: [
        SignaturePadModule,
        NgxIntlTelInputModule,
        AngularEditorModule,
        MatRadioModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        appRoutingModule,
        MatSnackBarModule,
        ExportAsModule,
        NgxDaterangepickerMd.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),

    ],
    declarations: [
        ConfirmationDialog,
        AppComponent,
        HomeComponent,
        EmailTemplatesComponent,
        AddEmailTemplateComponent,
        AdminComponent,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        DashboardComponent,
        AddUserModal,
        UsersComponent,
        LoginStatusComponent,
        RoleManagementComponent,
        AddRoleComponent,
        SettingsComponent,
        ProfileComponent,
        ThankYouComponent,
        SafeHtmlPipe,
        CompaniesComponent
        ,
        NewHomeComponent,
        SignUpComponent
        ,
        LoginStatusComponent
    ],
    entryComponents: [AddUserModal, ConfirmationDialog],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
