import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RouterLink } from '@angular/router';

import { AuthenticationService } from '@app/_services';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    postData		: {};
    loginForm		: FormGroup;
    loading 		= false;
    submitted 		= false;
    returnUrl		: string;
    message 		= '';
	message_class 	= 	'';
    email 			= '';
    password 		= '';
    isChecked 		= '';
    logo 			= '';
    site_title		= '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/companies']);
        }
    }

	
    ngOnInit() {
		
			this.authenticationService.getSiteSettings()
					.then((result:any) => {
					console.log("result is here"+result.data.title);
						this.logo 		= result.data.logo;
						this.site_title = result.data.title;
				})
				.catch((error) => {
					
				});
		
        this.loginForm = this.formBuilder.group({
            email				: ['',[Validators.required, Validators.email]],
            password			: ['', Validators.required],
            isRemberMeChecked	: [''],
        });
		
		if(JSON.parse(localStorage.getItem('RememberMe')) !== null){
			
			this.loginForm.controls['email'].setValue(localStorage.getItem('email'));
			this.loginForm.controls['password'].setValue(localStorage.getItem('password'));
			this.loginForm.controls['isRemberMeChecked'].setValue(true);
			
		}

        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    navigateTo(val) {
		  this.router.navigate([val]);
	}
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
		
		if (this.f.isRemberMeChecked.value) {
			localStorage.setItem('RememberMe', 'true');
			localStorage.setItem('email', this.f.email.value);
			localStorage.setItem('password', this.f.password.value);
		} else {
			localStorage.removeItem('RememberMe');
			localStorage.removeItem('email');
			localStorage.removeItem('password');
		}
		

		this.postData  		= 	{
									'email'		:	this.f.email.value,
									'password'	:	this.f.password.value
								};

        this.loading = true;
        this.authenticationService.login(this.postData)
		.then((result:any) => {
					if(result.status === true){
						var currentUser = {'apiToken':result.data.api_token,'userId':result.data.user_id,'role':''};
						localStorage.setItem('currentUser',JSON.stringify(currentUser));
						this.message_class	=	'success';
						this.router.navigate(['companies']);
					}else{
						this.message_class	=	'danger';
					}
					this.message 			= 	result.message
		})
		.catch((error) => {
			this.message_class	=	'danger';	
			this.message 		= 	error;
			console.log(error)
		});
    }
}
