import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UserService } from "@app/_services/user.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material";
import { DatePipe } from "@angular/common";
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: "app-login-status",
  templateUrl: "./login-status.component.html",
  styleUrls: ["./login-status.component.less", "../users/users.component.less"],
})
export class LoginStatusComponent implements OnInit {
  // pageEvent: any;
  // rolesData: any;
  // userForm: FormGroup;
  // message = "";
  // message_class = "";
  // filter = "";
  // loading = false;
  // submitted = false;
  name: string | null = null;
  displayedColumns: string[] = [
    "IP Address",
    "Type Of Device",
    "Login At",
    "Info",
  ];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  // MatPaginator Inputs
  // length = 0;
  // pageSize = 10;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  id: any = null;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.params["id"];

    // this.userService.rolesData.subscribe((x) => {
    //   this.rolesData = x.role_permission;
    // });
  }

  ngOnInit() {
    this.userService.getresponse(this.id).then(
      (result: any) => {
        this.dataSource = new MatTableDataSource(result.data);
        this.dataSource.data.forEach((row) => {
          if (row.location_base_ip) {
            row.location_base_ip = JSON.parse(row.location_base_ip);
          }
        });

        this.name =
          result.user_info.first_name +
          (result.user_info.last_name ? " " + result.user_info.last_name : "");
      },
      (error) => {
        this.openSnackBar(error.error.message);
      }
    );
  }

  openSnackBar(message: any) {
    this._snackBar.open(message, "", {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
