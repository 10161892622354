import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent implements OnInit {

  postData		: {};
  loginForm		: FormGroup;
  loading 		= false;
  submitted 		= false;
  confirm_email_mismatch 		= false;
  returnUrl		: string;
  message 		:any= '';
message_class 	= 	'';
  email 			= '';
  password 		= '';
  isChecked 		= '';
  logo 			= '';
  site_title		= '';

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService
  ) { 
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
          this.router.navigate(['/']);
      }
  }

  ngOnInit() {
     

      const passwordPattern = /^(?=.*[@?><~:+_)(*&^%$£!#])[A-Za-z\d@?><~:+_)(*&^%$£!#]{8,20}$/;
      this.loginForm = this.formBuilder.group({
          first_name: ['', [Validators.required, Validators.maxLength(50)]],
          last_name: ['', [Validators.required, Validators.maxLength(50)]],
          // company_name: [''],
          // address_line_1: ['', [Validators.required, Validators.maxLength(150)]],
          // address_line_2: ['', [Validators.required, Validators.maxLength(150)]],
          // address_line_3: ['', [Validators.required, Validators.maxLength(150)]],
          // postcode: ['', [Validators.required, Validators.maxLength(10)]],
          phone_no: ['',],
          email : ['',[Validators.required, Validators.email]],
          ConfrimEmail : ['',[Validators.required, Validators.email]],

          password: ['', [Validators.required, Validators.pattern(passwordPattern)]],
          password_confirmation: ['', [Validators.required, Validators.pattern(passwordPattern)]],
      },{ validator: this.passwordMatchValidator });
  }


// Custom validator function
passwordMatchValidator(group: FormGroup) {
  const password = group.get('password').value;
  const confirmPasswordControl = group.get('password_confirmation');
  const confirmPassword = confirmPasswordControl.value;

  if(confirmPassword == '') {
      confirmPasswordControl.setErrors({ required: true });
      return { required: true };
  }

  if (!confirmPassword || password === confirmPassword) {
      if (confirmPasswordControl.errors) {
          if (confirmPasswordControl.errors.mismatch) {
              delete confirmPasswordControl.errors.mismatch;
          }
          if (confirmPasswordControl.errors.required && !confirmPassword) {
              delete confirmPasswordControl.errors.required;
          }

          if (Object.keys(confirmPasswordControl.errors).length === 0) {
              confirmPasswordControl.setErrors(null);
          }
      }
      return null;
  } else {
      confirmPasswordControl.setErrors({ mismatch: true });
      return { mismatch: true };
  }
}



  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  navigateTo(val: string) {
    this.router.navigate([val]);
}
  onSubmit() {
      this.submitted = true;
      this.confirm_email_mismatch = false;
      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }
      
      // if(this.f.email.value != this.f.ConfrimEmail.value){
      //   this.confirm_email_mismatch = true;
      //   return;
      // }

     

      this.postData  		= 	{
        'first_name':	this.f.first_name.value,
        'last_name':	this.f.last_name.value,
        // 'company_name':	this.f.company_name.value,
        // 'address_line_1':	this.f.address_line_1.value,
        // 'address_line_2':	this.f.address_line_2.value,
        // 'address_line_3':	this.f.address_line_3.value,
        // 'postcode':	this.f.postcode.value,
        'email'		:	this.f.email.value,
        'ConfrimEmail': this.f.ConfrimEmail.value,
        'phone'		:	this.f.phone_no.value,
        'password'	:	this.f.password.value,
        'confirm_password'	:	this.f.password_confirmation.value,
        'role_id':2,
        };

        this.loading = true;
      this.authenticationService.signup(this.postData)
  .then((result:any) => {
    this.loading = false;
        if(result.status === true){
          // var currentUser = {'apiToken':result.data.api_token,'userId':result.data.user_id,'role':''};
          // localStorage.setItem('currentUser',JSON.stringify(currentUser));
          this.message_class	=	'success';
          
                      const router_ = this.router;
                      setTimeout(function () {
                          router_.navigate(['login']);
                       }, 4000)
          
        }else{
          this.message_class	=	'danger';
        }
        this.message 			= 	result.message
  })
  .catch((error) => {
    this.loading = false;
    this.message_class	=	'danger';	
    this.message 		= 	error;
    console.log(error)
  });
  }
  

}
