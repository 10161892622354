import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfirmedValidator } from '../confirmed.validator';

import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

    forgotForm		: FormGroup;
    loading 		= false;
    submitted		= false;
    returnUrl		: string;
    token 			= '';
    error 			= '';
	message 		= '';
	message_class 	= 	'';
	CurrentState 	= 'Verified';
	logo 	= '';
    site_title      = '';

    constructor(
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { 
        
		
		
				this.activatedRoute.params.subscribe( params => {
					this.token = params.token;
					if(this.token ==undefined){
						this.navigateTo('/')
					}
				});
		
    }

    ngOnInit() {
		
				this.authenticationService.getSiteSettings()
					.then((result:any) => {
						this.logo = result.data.logo;
                        this.site_title = result.data.title;
				})
				.catch((error) => {
					
				});
		
        this.forgotForm = this.formBuilder.group({
            password			: ['',[Validators.required,Validators.minLength(6)]],
			confirm_password	: ['', [Validators.required]]
			}, { 
				validator: ConfirmedValidator('password', 'confirm_password')
			
        });

        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotForm.controls; }

    navigateTo(val) {
		  this.router.navigate([val]);
	}
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotForm.invalid) {
            return;
        }

        this.loading = true;
		let data 	=	this.forgotForm.getRawValue();		
		
         this.authenticationService.resetPass(this.token,data)
            .then((result: any) => {
                this.loading = false;
                if (result.status === true) {
					this.message_class	=	'success';
                    const router_ = this.router;
                    setTimeout(function () {
                        router_.navigate(['login']);
                     }, 3000)
                } else {
					this.message_class	=	'danger';
                }
				this.message 			= 	result.message
               
            })
            .catch((error) => {
                this.loading = false;
				this.message_class		=	'danger';
				this.message 			= 	error;
            });
    }

}
