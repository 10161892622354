import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {

    forgotForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error 		= '';
	message 		= '';
	message_class 	= 	'';
	logo 	= 	'';
    site_title      = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
		
				this.authenticationService.getSiteSettings()
					.then((result:any) => {
						this.logo = result.data.logo;
                        this.site_title = result.data.title;
				})
				.catch((error) => {
					
				});
		
        this.forgotForm = this.formBuilder.group({
            email	: ['',[Validators.required, Validators.email]],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotForm.controls; }

    navigateTo(val) {
		  this.router.navigate([val]);
	}
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotForm.invalid) {
            return;
        }
		let data = { email : this.f.email.value }

        this.loading = true;
        this.authenticationService.forgot(data)
            .then((result: any) => {
                this.loading = false;
                if (result.status === true) {
					this.message_class	=	'success';
                } else {
					this.message_class	=	'danger';
                }
				this.message 			= 	result.message
               
            })
            .catch((error) => {
                this.loading = false;
				this.message_class		=	'danger';
				this.message 			= 	error;
            });
    }

}
